export function dateFormatter(date){
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
  };



  return  new Date(date).toLocaleString('en-US',{hour12:false}).replace(',',' ').replace(/\//g,'-').slice(0,-3)
  //String(new Intl.DateTimeFormat("en-US",options).format(new Date(date))).replace(',',' - ')
}
