<template>
  <div class="container-fluid mt-3">
    <div class="d-flex justify-content-between flex-wrap">

      <div class="d-flex flex-wrap mb-3 mb-sm-0 justify-content-between flex-grow-1">
        <Breadcrumb current_page="ألبومات الصور"/>
        <el-button class="ms-2" type="success" @click="$router.push('/create-album')">
          إنشاء معرض صور
        </el-button>
      </div>

      <collapse-filter>
        <form @submit.prevent="handleSearch" class="d-flex gap-3 flex-wrap filter-form">
          <div class="form-group">
            <label>بحث بحسب ال id</label>
            <input type="text" v-model="id" class="form-control"/>
          </div>

          <div class="form-group">
            <label>العنوان</label>
            <input type="text" v-model="title" class="form-control"/>
          </div>

          <div class="form-group">
            <label class="d-block">التصنيف</label>
            <el-select v-model="category_id" filterable placeholder="Select">
              <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>التاريخ</label>
            <v-date-picker v-model="dateRange" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                      :value="inputValue.start ? `${inputValue.start} - ${inputValue.end}` : ''"
                      v-on="inputEvents.start"
                      class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="form-group align-self-end ">
            <button class="btn btn-search px-3" type="submit" @click="handleSearch" :disabled="loading">
              بحث
            </button>
          </div>
        </form>
      </collapse-filter>
    </div>
    <el-card class="mt-2">

      <el-table id="top-table" :data="gallery" class="mb-4" stripe style="width: 100%" max-height="850"
                v-loading="loading">

        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
        <el-table-column prop="cover_photo" class-name="px-3" label="صورة الغلاف" width="100">
          <template #default="scope">
            <img :src="APP_URL + '70x70/' + scope.row.cover_photo" :alt="scope.row.id">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="عنوان الألبوم" width="200"/>
        <el-table-column prop="created_by" class-name="px-3" label="الناشر" width="130"/>
        <el-table-column prop="no_of_photos" label="عدد الصور" width="100"/>
        <el-table-column label="التاريخ" width="100">
          <template #default="scope">
            <span dir="ltr">
              {{ dateFormatter(scope.row.created_at) }}
            </span>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="gallery.length" v-model="page" :records="total" :per-page="per_page"
                  :options="paginationOptions" @paginate="fetchAllGallery"/>

    </el-card>

  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";
import {getAlbums} from "@/services/albums";
import {mapGetters} from "vuex";
import CollapseFilter from "../../components/CollapseFilter";
import {dateFormatter} from "../../utils/formatDate";

export default {
  name      : "Album",
  components: {
    Loader,
    Breadcrumb,
    CollapseFilter
  },
  data()
  {
    return {
      gallery          : [],
      loading          : false,
      id               : '',
      title            : '',
      category_id      : '',
      dateRange        : {
        start: '',
        end  : ''
      },
      page             : 1,
      total            : 1,
      per_page         : 1,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
        //hideCount: true
      }
    }
  },
  mounted()
  {
    this.fetchAllGallery();
  },
  computed: {
    APP_URL      : function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
    dateFormatter: () => date => dateFormatter(date),
    ...mapGetters(['categories'])
  },
  methods : {
    fetchAllGallery()
    {
      this.loading = true
      getAlbums(this.page)
          .then(res => {
            this.total    = res.data.total;
            this.per_page = res.data.per_page;
            this.gallery  = res.data.data;
            this.loading  = false
          })
          .catch(() => {
            this.loading = false
          })
    },
    handleSearch()
    {
      console.log('test')
    }
  }
}
</script>
