import request from "@/utils/request";

export function getAlbums(page = 1) {
  return request(
    {
      url: `albums?page=${page}`,
      method: "get"
    }
  )
}


export function createAlbum(data){
  return request(
    {
      url: "albums",
      method: "post",
      data
    }
  )
}
